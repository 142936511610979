import * as React from 'react';

// @mui
import { Container, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// hooks
import useSettings from '../hooks/useSettings';
// components
import DashboardLayout from '../layouts/dashboard';

import Page from '../components/Page';

import AppWelcome from '../components/AppWelcome';

import {AboutDataset} from "../sections";
import {CompanyData} from "../data";
import {usePolkawatchApi} from "../contexts/PolkawatchAPIConext";

// ----------------------------------------------------------------------

export default function HomePage() {
    const { themeStretch } = useSettings();
    const theme = useTheme();

    const { chainMeta } = usePolkawatchApi();

    const loaded = chainMeta.ID !== undefined;

    return (
        <DashboardLayout>
            <Page title="Polkawatch">
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <AppWelcome
                              title={'Welcome to Polkawatch'}
                              subtitle={`${chainMeta.Name} Decentralization Analytics`}
                              description={
                                chainMeta.HasStaking ?
                                    `Learn about ${chainMeta.Name} Public Validation Network. Lets help decentralize ${chainMeta.Name} with a smart Nomination !`:
                                    `Learn about ${chainMeta.Name} Collator Network. Help decentralize ${chainMeta.Name} via ${chainMeta.RelayChain} OpenGov !`
                              }
                              action={chainMeta.HasStaking ? 'Review my Nomination' : 'Participate in Governance' }
                              url={chainMeta.HasStaking ? '/nomination' : `https://${chainMeta.RelayChain}.polkassembly.io/gov-2` }
                              action2={'Learn more'}
                              url2={chainMeta.IsParachain ? CompanyData.learn_more_parachain_url :CompanyData.learn_more_url}
                              loaded={loaded}
                            />
                        </Grid>
                        <AboutDataset/>
                    </Grid>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
