import * as React from 'react';

import { Link as RouterLink } from 'gatsby';
// @mui
import { styled } from '@mui/material/styles';
import {Stack, Typography, Button, Card, CardContent, CardProps, Skeleton, useTheme} from '@mui/material';
import { SeoIllustration } from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

// ----------------------------------------------------------------------

interface AppWelcomeProps extends CardProps {
  title: string;
  subtitle: string;
  description: string;
  action: string;
  action2: string;
  url: string;
  url2: string;
  loaded: boolean;
}

export default function AppWelcome({title, subtitle, description, action, url, action2, url2, loaded=false}: AppWelcomeProps) {
  const theme = useTheme();
  return (
    <RootStyle>
      <CardContent
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          color: 'grey.800'
        }}
      >
        <Typography gutterBottom variant="h4">
          {loaded ? title: <Skeleton animation={"wave"} width={300} sx={{ bgcolor: theme.palette.primary.lighter }} /> }
          <br />
          {loaded ? subtitle: <Skeleton animation={"wave"} width={500} sx={{ bgcolor: theme.palette.primary.lighter }} /> }
        </Typography>

        <Typography variant="body2" sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480, mx: 'auto' }}>
            {loaded ? description: <Skeleton animation={"wave"} width={700} sx={{ bgcolor: theme.palette.primary.lighter }} /> }
        </Typography>

        <Stack direction="row" spacing={1.5}>
          <Button variant="contained" to={url} component={RouterLink} target={url.toUpperCase().startsWith('HTTP') ? '_blank' : '_self' }>
            {action}
          </Button>
          { url2 && (
              <Button variant="contained" color={'secondary'} component="a" href={url2} target={'_blank'}>
                  {action2}
              </Button>
          )}
        </Stack>
      </CardContent>

      <SeoIllustration
        sx={{
          p: 3,
          width: 360,
          margin: { xs: 'auto', md: 'inherit' }
        }}
      />
    </RootStyle>
  );
}
